import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  EzConfirmationComponent, EzInfoComponent, EzLandingComponent, EzPaymentAmountComponent, EzPaymentMethodComponent,
  EzReviewAuthComponent
} from 'anthem-eea-library-ui';
import { DynamicLoadComponent } from './../framework/dynamic-load.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'reusable-wcs-widgets-lazy', loadChildren: () => import('./../common/components/reusable-wcs-widgets/reusable-wcs-widgets.module').then(m => m.ReusableWcsWidgetsModule) },
  { path: 'app-password-expiry-lazy', loadChildren: () => import('./../pages/eea/password-expiry/password-expiry.module').then(m => m.PasswordExpiryModule) },
  { path: 'app-password-confirmation-lazy', loadChildren: () => import('./../pages/eea/password-confirmation/password-confirmation.module').then(m => m.PasswordConfirmationModule) },
  { path: 'secret-question-lazy', loadChildren: () => import('./../pages/eea/secret-question/secret-question.module').then(m => m.SecretQuestionModule) },
  //Login redesign starts
  { path: 'forgot-password-lazy', loadChildren: () => import('./../pages/eea/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
 // { path: 'app-member-login-lazy', loadChildren: () => import('./../pages/eea/member-login/member-login.module').then(m => m.MemberLoginModule) },
  { path: 'app-login-lazy', loadChildren: () => import('./../pages/eea/login/login.module').then(m => m.LoginModule) },
  { path: 'authentication-lazy', loadChildren: () => import('./../pages/eea/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'one-time-code-lazy', loadChildren: () => import('./../pages/eea/one-time-code/one-time-code.module').then(m => m.OneTimeCodeModule) },
  { path: 'change-password-lazy', loadChildren: () => import('./../pages/eea/change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'forgot-username-lazy', loadChildren: () => import('./../pages/eea/forgot-username/forgot-username.module').then(m => m.ForgotUserNameModule) },
  // { path: 'forgot-password-lazy', loadChildren: () => import('./../pages/eea/forgot-password-v1/forgot-password-v1.module').then(m => m.ForgotPasswordModuleV1) },
  // { path: 'app-member-login-lazy', loadChildren: () => import('./../pages/eea/member-login-v1/member-login-v1.module').then(m => m.MemberLoginModuleV1) },
  // { path: 'app-login-lazy', loadChildren: () => import('../pages/eea/login-v1/login-v1.module').then(m => m.LoginModuleV1) },
  // { path: 'authentication-lazy', loadChildren: () => import('./../pages/eea/authentication-v1/authentication-v1.module').then(m => m.AuthenticationModuleV1) },
  // { path: 'one-time-code-lazy', loadChildren: () => import('./../pages/eea/one-time-code-v1/one-time-code-v1.module').then(m => m.OneTimeCodeModuleV1) },
  // { path: 'change-password-lazy', loadChildren: () => import('./../pages/eea/change-password-v1/change-password-v1.module').then(m => m.ChangePasswordModuleV1) },
  // { path: 'forgot-username-lazy', loadChildren: () => import('./../pages/eea/forgot-username-v1/forgot-username-v1.module').then(m => m.ForgotUserNameModuleV1) },
  //Login redesign ends
  { path: 'confirmation-lazy', loadChildren: () => import('./../pages/eea/confirmation/confirmation.module').then(m => m.ConfirmationModule) },
  { path: 'registration-lazy', loadChildren: () => import('./../pages/eea/registration/registration.module').then(m => m.RegistrationModule) },
  { path: 'easypay-external-lazy', loadChildren: () => import('./../pages/easypay-external/easypay-external.module').then(m => m.EasyPayExternalModule) },
  { path: 'mobile-login-lazy', loadChildren: () => import('./../pages/ema/mobile-login/mobile-login.module').then(m => m.MobileLoginModule) },
  { path: 'eamobileapp-lazy', loadChildren: () => import('./../pages/eea/eamobileapp/eamobileapp.module').then(m => m.EamobileappModule) },
  {
    path: 'easypay-external',
    component: DynamicLoadComponent,
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: 'welcome', component: EzLandingComponent },
      { path: 'confirmation', component: EzConfirmationComponent },
      { path: 'payment-method', component: EzPaymentMethodComponent },
      { path: 'payment-amount', component: EzPaymentAmountComponent },
      { path: 'review-auth', component: EzReviewAuthComponent },
      { path: 'information', component: EzInfoComponent },

    ]
  },
  { path: 'hard-stop-lazy', loadChildren: () => import('./../pages/eea/hard-stop/hard-stop.module').then(m => m.hardStopModule) },
  //{ path: 'employer-registration-lazy', loadChildren: () => import('./../pages/eea/employer-registration/employer-registration.module').then(m => m.EmployerRegistrationModule) },
  { path: 'electronic-enrollment-setup-lazy', loadChildren: () => import('./../pages/eea/electronic-enrollment-setup/electronic-enrollment-setup.module').then(m => m.ElectronicEnrollmentSetupModule) },
  { path: 'data-safety-declaration-lazy', loadChildren: () => import('./../pages/eea/data-safety-declaration/dataSafetyDeclaration.module').then(m => m.DataSafetyDeclarationModule) },
  //{ path: 'member-registration-lazy', loadChildren: () => import('./../pages/eea/member-registration/member-registration.module').then(m => m.MemberRegistrationModule) },
  { path: 'broker-registration-lazy', loadChildren: () => import('./../pages/eea/broker-registration/broker-registration.module').then(m => m.BrokerRegistrationModule) },
  { path: '**', component: DynamicLoadComponent },
 // { path: 'app-member-login-lazy', loadChildren: () => import('./../pages/eea/member-login/member-login.module').then(m => m.MemberLoginModule) },
  { path: 'app-logout', loadChildren: () => import('./../pages/eea/logout/logout.module').then(m => m.LogoutModule) },
  { path: 'approve-deny-lazy', loadChildren: () => import('../pages/eea/self-registration/approve-deny/approve-deny.module').then(m => m.ApproveDenyModule) },
  { path: 'self-registration-lazy', loadChildren: () => import('../pages/eea/self-registration/self-registration.module').then(m => m.SelfRegistrationModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }