import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DynamicLoadModuleUtil } from 'anthem-eea-library-ui';


@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html'
})
export class HeaderTitleComponent implements OnInit, AfterContentInit {

  @Input() wcsdata: any;
  public isHome: boolean = false;
  public employerRegistrationWcsData: any;
  public isHideBckBtn: boolean = false;

  constructor(public router: Router) {

  }

  ngAfterContentInit() {
    if (this.wcsdata != null) {
      this.employerRegistrationWcsData = this.wcsdata.employerRegistrationHeader;
      switch (this.router.url) {
        case '/broker-registration':
          this.isHideBckBtn = true;
          break;
      }
    }
  }

  /**
   * Method navigate to previous page
   */
  navigatePreviousPage() {
    if(this.employerRegistrationWcsData.routePreviousPage != undefined && this.employerRegistrationWcsData.routePreviousPage != null && this.employerRegistrationWcsData.routePreviousPage != "") {
      this.router.navigate([this.employerRegistrationWcsData.routePreviousPage]);
    }
  }

  ngOnInit() {
  }

}
DynamicLoadModuleUtil.registerEntryComponent("HeaderTitleComponent", HeaderTitleComponent);
