import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  production: true,
  chatbot: {
	  url: '/',
        path: '/eea/public/api/chat-server/socket'
	  },
  web: {
    idleTimeLength: 780,
    sessionTimeoutLength: 120
  },
  wcsApi: {
    OWCSContent: true,
    baseUrlOWCS: '/eea/public/cms',
    baseUrlWebServer: '/eea/public/assets/wcs',
    errThresholdCount: 3,
  	sleepInterval: 300000,
    timeout:3000
  },
  strapi: {
    baseUrlStrapi: '/eea/public/wcm'
  },
  smDetails: {
    SM_FORM_URL: "siteminderagent/forms/login.fcc",
    LOGGEDIN_REDIRECT_URL: "/eea/secure",
    SM_URL_PARAM: "/siteminderagent/forms/login.fcc?target=/eea/secure/"
  },
  logDetails: {
    timeInterval: 60000
  },
  authDetails: {
    LOGOUT_REDIRECT_URL: "/eea/public/login"
  },
  environment: "prod",
  externalURLs: {
    "CS90_EMPIREBLUE_SELF_REGISTRATION": "https://pd9.secure.empirehealthcare.com/employerservices/employer/public/startReg",
    "ACES_NORTHEAST_SELF_REGISTRATION_CT": "https://www.anthem.com/forms/east/ct_egi.html",
    "ACES_NORTHEAST_SELF_REGISTRATION_ME": "https://www.anthem.com/forms/east/me_egi.html",
    "ACES_NORTHEAST_SELF_REGISTRATION_NH": "https://www.anthem.com/forms/east/nh_egi.html",
    "CHIPS_VIRGINIA_SELF_REGISTRATION": "https://www.anthem.com/employer/va/f5/s1/t0/pw_a034509.pdf",
    "BROKER_PORTAL_SELF_REGISTRATION": "https://brokerportal.anthem.com/ehb/web/bkr/acc/login.htm?wlp-brand=anthem",
	"BROKER_PORTAL_BLUEMA_SELF_REGISTRATION": "https://broker.bluemedadvgrhs.com/apps/ptb/",    
    "CS90_EMPIREBLUE_FORGOT_PASSWORD": "https://pd9.secure.empirehealthcare.com/employerservices/employer/public/forgetPassword"
  }
};
