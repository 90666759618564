import { Injectable } from '@angular/core';
import { ApiNameList } from '../../../../environments/api-name-list';
import { environment } from '../../../../environments/environment';
import { HttpClientService } from '../http-client.service';

@Injectable()
export class IdentityService {
    private restApi: any;
    private config: any;

    constructor(private httpClientService: HttpClientService) {
        this.restApi = ApiNameList.restApi;
        this.config = environment;
    }

    siteMinder(userID: string, password: string) {
        const requestObject = {
            'USER': userID,
            'PASSWORD': password,
            'TARGET': this.config.smDetails.LOGGEDIN_REDIRECT_URL
        };
        return this.httpClientService.post(this.restApi.baseUrl + this.config.smDetails.SM_FORM_URL, requestObject);
    }

    employerLogin(userId: string, password: string, multifactorAuth: boolean, encryptedUserId?: string, guid?: string) {
        const requestObject = {
            'userName': userId,
            'password': password,
            'multifactorAuth': multifactorAuth,
            'info': encryptedUserId
        };
        return this.httpClientService.post(this.restApi.baseUrl + this.restApi.LOGIN_API_URL, requestObject, null, guid);
    }

    changePassword(userId: string, currentKey: string, newKey: string) {
        const requestObject = {
            'userName': userId,
            'password': currentKey,
            'newPassword': newKey,
            'multifactorAuth': true
        };
        return this.httpClientService.post(this.restApi.baseUrl + this.restApi.CHANGE_PASSWORD_API_URL, requestObject);
    }

    validatePasswordExpiryLink(userId: string) {
        const requestObject = {
            'userName': userId
        };
        return this.httpClientService.post(this.restApi.baseUrl + this.restApi.VALIDATE_PASSWORD_EXPIRY_LINK, requestObject);
    }

    validateUserId(userName: string) {

        const requestObject = {
            'userName': userName
        };

        return this.httpClientService.post(this.restApi.baseUrl + this.restApi.VALIDATE_USER_ID, requestObject);

    }

    sendMobileAppInvitation(phoneNumber: string) {

        const requestObject = {
            'phoneNumber': phoneNumber
        };

        return this.httpClientService.post(this.restApi.baseUrl + this.restApi.SEND_MOBILE_APP_INVITATION, requestObject);

    }

    //Login Redesign starts 
    employerLoginNew(userId: string, password: string, multifactorAuth: boolean, guid?: string) {
        const requestObject = {
            'userId': userId,
            'password': password,
            'mfaCheck': multifactorAuth,
            'clientCorrelationId': guid
        };
        //return this.httpClientService.postNew('http://localhost:8085/public/api/eea/login', requestObject, null);
        return this.httpClientService.postNew(this.restApi.baseUrl + this.restApi.EMPLOYER_LOGIN_NEW, requestObject, null);
    }
    
    //ER-119852 #Enhance Email Address Validation and Centralize Code - Admin
    getRegex(reqData: any) {
        return this.httpClientService.post(
          this.restApi.baseUrl + this.restApi.GET_REGEX,
          reqData
        );
    }
}
