export class UtilityService {
    /**
     * Static Method Used to Format the Date into MM/DD/YYYY format
     * 
     * Input and respective format 
     * 
     * 010116 --> 01/01/2016
     * 01/01/16 --> 01/01/16
     * 1/1/7 ----> 01/01/2007
     * 1/01/82 -> 01/01/1982
     * 
     * 
     * value.indexOf('/')  Gives First index of '/'  where it is present   1/1/17 -> 1 ; 01/01/17 --> 2
     */

    static formatDate(value: string): string {
        let fmtDate = '';
        if (value != null && value.length > 0 && value.indexOf('/') == -1) {
            var numbers = value.replace(/\D/g, ''),
                char = { 2: '/', 4: '/' };
            for (var i = 0; i < numbers.length; i++) {
                fmtDate += (char[i] || '') + numbers[i];
            }
        } else {
            if (value != null && value.length > 0 && value.indexOf('/') != -1) {
                let dtStrArr: String[] = value.split('/');
                // If the String Array is 3 means  '/' is provided in all the places 
                if (dtStrArr != null && dtStrArr.length > 0 && dtStrArr.length === 3) {
                    let mnth: String = dtStrArr[0];
                    let dt: String = dtStrArr[1];
                    let yr: String = dtStrArr[2];

                    if (mnth != '' && mnth.length < 2) {
                        mnth = '0' + mnth;
                    }
                    if (dt != '' && dt.length < 2) {
                        dt = '0' + dt;
                    }
                    if (dt != '' && dt.length > 2) {
                        return 'invalid';
                    }

                    if (yr != '' && yr.length < 2) {

                        yr = '0' + yr
                    }
                    fmtDate = mnth + '/' + dt + '/' + yr;
                } else {
                    fmtDate = value;
                }
            } else {
                fmtDate = value;
            }
        }
        fmtDate = UtilityService.convertYear(fmtDate);
        return fmtDate;
    }

    static compareByDate(dt1: String, dt2: String): boolean {
        if (dt1.valueOf() === dt2.valueOf()) {
            return true;
        }
        else {
            return false;
        }
    }
    
    static isValidDate(dateString: string) {
        let tempYear = dateString.substring(dateString.lastIndexOf('/') + 1);
        if (tempYear.length > 4) {
            return false;
        }

        let valid: boolean = true;
        if (dateString != null && dateString.indexOf('/') != -1) {
            let dtStrArr = dateString.split('/');
            if (dtStrArr != null && dtStrArr.length == 3) {
                dateString = dateString.replace('/', '').replace('/', '');
            } else {
                return false;
            }
        }

        let month: any = parseInt(dateString.substring(0, 2), 10);
        let day: any = parseInt(dateString.substring(2, 4), 10);
        let year: any = parseInt(dateString.substring(4, 8), 10);

        if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
            if ((month < 1) || (month > 12)) {
                valid = false;
            } else if ((day < 1) || (day > 31)) {
                valid = false;
            } else if (((month == 4) || (month == 6) || (month == 9) || (month == 11)) && (day > 30)) {
                valid = false;
            } else if ((month == 2) && (((year % 400) == 0) || ((year % 4) == 0)) && ((year % 100) != 0) /*&& (day > 29)*/) {
                if (day > 29) {
                    valid = false;
                }
            } else if ((month == 2) && ((year % 100) == 0) /*&& (day > 29)*/) {
                if (day > 29) {
                    valid = false;
                }
            } else if ((month == 2) && (day > 28)) {
                valid = false;
            }
            if (year < 1000 || year > 9999) {
                valid = false;
            }
        } else {
            valid = false;
        }
        return valid;
    }

    static convertYear(fmtDate: string): string {

        let originalFormatedDate: string = fmtDate;
        let convertedYear: string;
        if (fmtDate.length == 8) {
            let twoDigitYear: any = fmtDate.substring(fmtDate.lastIndexOf('/') + 1);
            let finalDate: string = fmtDate.substring(0, fmtDate.lastIndexOf('/') + 1);
            let tempyear: string = '';

            if (twoDigitYear < 19) {
                tempyear = 20 + twoDigitYear;
                convertedYear = finalDate + tempyear;
            } else {
                tempyear = 19 + twoDigitYear;
                convertedYear = finalDate + tempyear;
            }
            return convertedYear;
        }
        else {
            return originalFormatedDate;
        }
    }

    /**
     * Helper Class used to check value is empty or null
    */ 
    static isNullorEmpty(value: any): boolean {
        if (value != null && value != "" && value != "undefined") {
            return false
        }
        return true;
    }

    /**
     * Helper Class used to compare two values 
    */ 
    static notEmptyAndEqual(value1: string, value2: string): boolean {
        if (value1 != null && value1 != "" && value2 != null && value2 != "") {
            if (value1.toUpperCase() === value2.toUpperCase()) {
                return true;
            }
        }
        return false;
    } 

       /**
     * Helper Class used to check null or empty 
        */
        static notNullAndNotEmpty(value1: string): boolean {
        if (value1 != undefined && value1 != null && value1 != '') {
            return true;
        }
        return false;
    }

    static generateUniqueId() {
		//let randomNumber: number = Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1) + 1000000000000000);
		let randomNumber: number = Math.floor(Math.random() * 9999999999999999);    	
		return randomNumber.toString().substring(0, 16);
	}
}