import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../constants/app-constants';
import { UtilityService } from './eea/utility.service';
import { UserCacheService } from './user-cache.service';

@Injectable()
export class HttpClientService {
  constructor(
    private http: HttpClient,
    private userCacheService: UserCacheService
  ) { }

  createCustomHeaders(headerParam?: HttpHeaders) {
    // Add custom headers
    if (headerParam) {
      return headerParam;
    } else {
      let headers = new HttpHeaders();
      return headers.set('Content-Type', 'application/json');
    }

  }

  get(url) {
    return this.http.get(url);
  }

  post(url, data, headerParam?: HttpHeaders, guid?: string) {
    const headers = this.createCustomHeaders(headerParam);

    data.requestHeader = {
      messageId: guid ? guid : '123457',
      appId: 'EEA',
      securityCredential: {
        userName: this.userCacheService.getUserData(AppConstants.USERID) || '',
        securityToken:
          this.userCacheService.getUserData(AppConstants.JWT_TOKEN) || ''
      }
    };
    return this.http.post(url, JSON.stringify(data), { headers: headers });
  }
  postNew(url, data, headerParam?: HttpHeaders) {
    const headers = this.createCustomHeaders(headerParam);
    return this.http.post(url, JSON.stringify(data), { headers: headers });
  }

  postNodeAPI(url, data, headerParam?: HttpHeaders, guid?: string) {
    const headers = this.createCustomHeaders(headerParam);
    data.traceId = UtilityService.generateUniqueId();
    data.requestHeader = {
      messageId: guid ? guid : '123457',
      appId: 'EEA',
      securityCredential: {
        userName: this.userCacheService.getUserData(AppConstants.USERID) || '',
        securityToken:
          this.userCacheService.getUserData(AppConstants.JWT_TOKEN) || ''
      }
    };
    return this.http.post(url, JSON.stringify(data), { headers: headers });
  }
}
