import {Component,Inject, Input, OnInit, ViewChild, ElementRef, EventEmitter, Output, HostListener} from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { ChatHandler } from '../../../common/services/chat.service'
import { UtilityService } from '../../services/eea/utility.service';
import { WcsApiService } from '../../services/wcs-api.service';
import { AppConstants } from '../../constants/app-constants';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment' ;
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styles: []
})
export class ChatComponent implements OnInit {
  chatShow: boolean = false;
  chatMaximized: boolean = false;
  responseLink: string;
  selectedAction: string;
  userResponse: any;
  actionResponse: any;
  selectedActions: any = [];
  unreadMessages: number = 0;
  //chatMessages: any[] = [];
  private wcsApi: any;
  
connected: boolean = false;
loading: boolean = true;
closed: boolean = false;
chatMessages: any[] = [];
  public chatSubscription: Subscription;
public botIsTyping: boolean = false;
public userIsTyping: boolean = false;
public ischatBotActive:boolean=false;
public loginFailed:boolean = false;
private minimizeBot:boolean = false;
@Output() miniMizeChatBot=new EventEmitter<boolean>();
public dateTime: number;
public timeOutSocket:any;
public enableACAVersion1: boolean = false;
public enableACAVersion2: boolean = false;
sampleMessages: string[] = [
    "Type your reply here",
  ];
  samplePlaceholder: string = 'Type your response and hit enter';

  sampleMessage: string = this.sampleMessages[0];
inputMessage: string = null;
inputPlaceholder: string = '';
  actionResponses  = [
    {
      'label': "Make a Payment",
      'response': "<a (click)='navigateToPath(/make-a-payment) href=javascript:void(0)'>Make a Payment online</a> or you can call 1-888-999-7777 to make a payment over the phone",
    },
    {
      'label': "Find a Doctor",
      'response': "If you don't know the provider's code, you can <a (click)='navigateToPath(/search-provider) href=javascript:void(0)'>Search Providers here.</a>",
    },
    {
      'label': "Find a Form",
      'response': "<a (click)='navigateToPath(/find-user-form) href=javascript:void(0)'>Search Available Forms here.</a>",
    }
  ];
  @ViewChild('textInputArea',{ static: false })
  _textInputArea: ElementRef;

  constructor(@Inject(DOCUMENT) private document: any,
		  	  public router: Router, 
		  	  public chatHandler : ChatHandler,
		  	  public wcsApiService: WcsApiService) {
            this.wcsApi = environment.wcsApi;
  }

  ngOnInit() {
    
    if (this.wcsApi.OWCSContent) {
      this.wcsApiService.getWcsContent('/login').subscribe(response => {
        this.parseData(response);
      });
    } else {
      this.wcsApiService.getWebServerContent('/login.html').subscribe(res => {
        this.parseData(res);
      });
    }
    this.selectedAction = 'none';
    this.actionResponse = 'none';
    let i = Math.floor(Math.random() * (this.sampleMessages.length));
    this.inputPlaceholder = this.sampleMessages[i];

    this.requestObjectData();

    this.connected = this.chatHandler.isConnected();
    
    //Subscribe to bot typing
    this.chatHandler.isBotTyping().subscribe(isTyping => {
      this.botIsTyping = isTyping;
      if (this.botIsTyping) {
        if (this.chatMessages[this.chatMessages.length - 1].sender === 'user') {
          this.groupMessages()
        }
        setTimeout(() => this.scrollToBottom(), 0);
      }
    });

    this.chatHandler.isUserTyping().subscribe(isTyping => {
      this.userIsTyping = isTyping;
      if (this.userIsTyping) {
        if (this.chatMessages[this.chatMessages.length - 1].sender === 'bot') {
          this.groupMessages()
        }
        setTimeout(() => this.scrollToBottom(), 0);
      }
    });

    this.chatHandler.isChatClosed().subscribe(isClosed => {
      this.closed = isClosed;
      if (this.closed) {
        this.inputMessage = null;
        this.inputPlaceholder = '';
      }
    });

    this.chatHandler.countUnreadMsgObservable.subscribe((res)=>{      
      if(!this.chatMaximized)
      {
        this.unreadMessages=this.unreadMessages+1;
      }
    
      else
      this.unreadMessages=0;
     })

    //Subscribe on socket connect
    this.chatHandler.onConnection().subscribe(isConnected => {
      this.connected = isConnected;
      if (this.loading && this.connected) {
        setTimeout(() => {
          this.rotateDemoMessages();
          // this.speakLastMessage();
          this.initAutoScrollToBottom();
        }, 100);
        //Should load once
        this.loading = this.loading ? !this.connected : this.loading;
        this.dateTime = Date.now();
      }
    });
    this.chatHandler.connect(this.chatMaximized);
   // this.speechHandler.allowSpeech(false);
   this.chatHandler.customObservable.subscribe((res) => {
    this.scrollToBottom();
   });

   this.chatHandler.chatExpanModalObservable.subscribe((res) => {
    if(res){
     if(this.chatMessages.length>0){
      this.chatMaximized=true;
        this.unreadMessages = 0;
        this.ischatBotActive=false;
        this.loginFailed = true;
        this.requestObjectData();
        this.chatHandler.hideActiveButtons();
        clearTimeout(this.timeOutSocket)
        this.chatHandler.resetChat();
        this.chatHandler.connect(true);
        this.chatMessages = this.chatHandler.getChatMessages();
     }
     else{
     this.chatMaximized=true;
     this.unreadMessages = 0;
     this.ischatBotActive=true;
     this.loginFailed = false;
     this.requestObjectData();
     clearTimeout(this.timeOutSocket)
     this.chatHandler.resetChat();
     this.chatHandler.connect(true);
     this.chatMessages = this.chatHandler.getChatMessages();
    }
    }
 })

    this.chatHandler.minimizeChatOutsideChatBotObservable.subscribe((res) => {
      if(!res){
        this.chatMaximized=false;
      }
    })
  }
  
  public requestObjectData() {
    //Set Profile Object Based On enable ACA Version
    //set ACA1.0 profile Object
    if (this.enableACAVersion1) {
      this.chatHandler.profile = {
        chatActive: this.ischatBotActive,
        loginFailed: this.loginFailed,
        channel: AppConstants.EVA_CHANNEL
      };
    }
    //set ACA2.0 profile Object
    else {
      this.chatHandler.profile = [
        { key: 'chatActive', value: this.ischatBotActive },
        { key: 'loginFailed', value: this.loginFailed },
        { key: 'channel', value: AppConstants.EVA_CHANNEL },
        { key: 'isChatOnlyUser', value: false },
        { key: 'isTPAUser', value: false },
      ];
    }
  }

  parseData(response){
   let parser: any = new DOMParser();
  try{
   let htmlDocument: any = parser.parseFromString(response, "text/html");
   let json = JSON.parse(htmlDocument.getElementsByTagName('script')[0].innerText);
   }catch(e){
	   this.enableACAVersion1 = false;
   }
  }
  
  toggleChat() {
    this.chatMaximized = !this.chatMaximized;
    setTimeout(() => this.scrollToBottom(), 0);
    if (this.chatMaximized) {
      this.chatHandler.connect(true);
      this.chatMessages = this.chatHandler.getChatMessages();
      this.unreadMessages=0;
      }
  }
  groupMessages() {
	    let elementList = document.querySelectorAll(".not-first-message")
	    if (elementList.length > 0) {
	      elementList[elementList.length - 1].setAttribute('id','lastMessage')
	    }
	  }
  
  navigateToPath(path) {
    this.router.navigate(path);
  }
  buildResponseLink(path) {
    this.responseLink = path;
  }
  selectAction(path, label) {
    this.chatHandler.hideActiveButtons();
    if (UtilityService.notNullAndNotEmpty(label.trim())) {
      this.chatHandler.sendChatMessage('user', {text: label});
    }
  }
  sendUserResponse(response) {
	  if (UtilityService.notNullAndNotEmpty(response.trim())){
		   this.chatHandler.hideActiveButtons();
		   this.chatHandler.sendChatMessage('user', {text: response.trim()});
	  }
   this.userResponse = '';
  }

  
  
  private scrollToBottom() {
	    let mainDiv = this.document.getElementById('main');
	    if (mainDiv) {
	      mainDiv.scrollTop = mainDiv.scrollHeight - mainDiv.clientHeight;
	    }
	  }
  
  public rotateDemoMessages() {
	    setInterval(() => {
	      let index = this.sampleMessages.indexOf(this.sampleMessage);
	      if (index === this.sampleMessages.length - 1) {
	        index = -1;
	      }
	      this.sampleMessage = this.sampleMessages[index + 1];
	    }, 2000);
	    
	  }
/*
	  private speakLastMessage() {
	    if (this.chatMessages.length > 0 && this.chatMessages[this.chatMessages.length - 1]['sender'] === 'bot') {
	      this.speechHandler.textToSpeech(
	        this.chatMessages[this.chatMessages.length - 1]['message']['text']
	      );
	    }
	  }
	*/
  public initAutoScrollToBottom() {
		    let previousMessagesCount = 0;
		    setInterval(() => {
		      let currentMessagesCount = this.chatHandler.getChatMessages().length;
		      if (currentMessagesCount > previousMessagesCount) {
		        previousMessagesCount = currentMessagesCount;
		        this.scrollToBottom();
		      }
		    }, 0);
      }

    @HostListener('click', ['$event.target']) onClick(event: any) { 
      this.handleNavigation(event);
      this.minimizeBot = true;
      this.miniMizeChatBot.emit(this.minimizeBot);
    }
    
     /**
   * Navigation to route
   * @param event 
   */
  public handleNavigation(event) {
    let className = event.className.trim();
    if (UtilityService.notNullAndNotEmpty(className)) {
      let routeName = className.split("*")
      if (routeName[0] == 'navigation') {
        this.router.navigate([routeName[1]]);
    }
  } 
}
}
