import { DtmDigitalData } from './digitalData';
import { Injectable, Inject } from '@angular/core';
import { UserCacheService } from "./user-cache.service";


declare var _satellite: any;

@Injectable()
export class DataAnalyticsService {
    private _digitalData = new DtmDigitalData(this._userCacheService, this._window);
    constructor(private _userCacheService: UserCacheService,
        @Inject('Window') private _window: any) {
        this._digitalData.setSessionData(this._userCacheService.getAllUserCacheData());
        _window.digitalData = this._digitalData.data;
    }

    pageChanged(eventName?: string, customerObj?: any) {
        if (customerObj) {
            this._digitalData.setSessionData(customerObj);
        }
        else {
            this._digitalData.setSessionData(this._userCacheService.getAllUserCacheData());
        }

        this._digitalData.setPageData();
        this._window.digitalData = this._digitalData.data;
        if (typeof _satellite !== 'undefined') {
            if (eventName) {
                _satellite.track(eventName);
            } else {
                _satellite.track('URLchanged');
            }
        }
    }

}
