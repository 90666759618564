import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurePrivacyBlockComponent } from './secure-privacy-block/secure-privacy-block.component';
import { HeaderTitleComponent } from "./header-title/header-title.component";

@NgModule({
  imports: [
    CommonModule  ],
  declarations: [
    HeaderTitleComponent,
    SecurePrivacyBlockComponent
  ],
  bootstrap: [
    HeaderTitleComponent,
    SecurePrivacyBlockComponent
  ],
  exports: [
    HeaderTitleComponent,
    SecurePrivacyBlockComponent  ]
})
export class ReusableWcsWidgetsModule { }
