import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiNameList } from '../../../environments/api-name-list';
import { AppConstants } from '../../common/constants/app-constants';
import { UserCacheService } from "../../common/services/user-cache.service";
import {timeout} from 'rxjs/operators'; 

@Injectable()
export class WcsApiService {

  private wcsApi: any;
  private webApi: any;
  private locale: any;

  constructor(private http: HttpClient, private userCacheService: UserCacheService) {
    this.wcsApi = environment.wcsApi;
    this.webApi = ApiNameList.restApi;
    this.wcsApi.baseUrl = this.wcsApi.OWCSContent ? location.origin + this.wcsApi.baseUrlOWCS : location.origin + this.wcsApi.baseUrlWebServer; //Rewrite the baseUrl.
    if (this.userCacheService.getUserData(AppConstants.LOCALE) === undefined || this.userCacheService.getUserData(AppConstants.LOCALE) === null || this.userCacheService.getUserData(AppConstants.LOCALE) === "") {
      this.locale = AppConstants.ENGLISH;
    } else {
      this.locale = this.userCacheService.getUserData(AppConstants.LOCALE);
    }
  }

  /**
   * Method to fetch WCS template based on page route. This API will force timeout if no response 
   * received within wcsApi.timeout  
   * @param pageRoute 
   */	
  getWcsContent(pageRoute: string) {
    let basePath = environment.strapi.baseUrlStrapi;
    let wcsPages = this.userCacheService.getUserData('WCS_PAGES');
    if (wcsPages && wcsPages.includes(pageRoute)) {
      basePath = this.wcsApi.baseUrl;
    }
    return this.http.get(basePath + pageRoute + '?locale=' + this.locale, { responseType: 'text'})
      .pipe(
        timeout(this.wcsApi.timeout)
      );
  }

  /**
   * Method to fetch Web Server template based on page route.
   * @param pageRoute 
   */
  getWebServerContent(pageRoute: string) {
    return this.http.get(location.origin + this.wcsApi.baseUrlWebServer + pageRoute + '?locale=' + this.locale, { responseType: 'text' });
  }

  /**
   * Method to fetch the WCS footer content.
   * @param param 
   */
  getWcsFooterContent(param: string) {
    return this.http.get(environment.strapi.baseUrlStrapi + param + '?locale=' + this.locale, { responseType: 'json' })
    .pipe( 
      timeout(this.wcsApi.timeout)
   );
  }

  /**
   * Method to fetch the Web Server footer content.
   */
  getWebServerFooterContent() {
    return this.http.get(location.origin + this.wcsApi.baseUrlWebServer + '/footer-info.json?locale=' + this.locale, { responseType: 'json' });
  }
}