import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserCacheService } from "./user-cache.service";
import { IWindow } from "../components/models/iWindow";
import { environment } from '../../../environments/environment';


export class DtmDigitalData {
    data = {
        page: {
            environment: ""
        },
        customer: {
            webGuid: "",
            underwritingState: "",
            userType: "",
            groupId: "",
            lineOfBusiness: "",
            sourceSystemId: "",
            brand: "",
            correlationId:""
        }
    };
    subGroupId: any = [];
    productType: any = [];

    constructor(private _userCacheService: UserCacheService, private _window: IWindow) { }

    setPageData() {

    }

    setSessionData(userCache: any) {
        this.data.customer.webGuid = userCache.userId || '';
        this.data.customer.userType = userCache.userType || '';
        this.data.customer.brand = userCache.brand || '';
        this.data.customer.groupId = userCache.groupNumber || '';
        this.data.customer.sourceSystemId = userCache.srcSystem || '';
        this.data.customer.underwritingState = userCache.stateCode || '';
        this.data.customer.lineOfBusiness = userCache.mbu || '';
        this.data.customer.correlationId = userCache.ClientCorrelationId || '';
        this.data.page.environment = environment.environment;
    }

}




