export class AppConstants {
    public static get APPLICATION_PDF(): string { return 'application/pdf'; }
    public static get APPLICATION_MS_EXCEL(): string { return 'application/vnd.ms-excel'; }
    public static get REUSABLE_WCS_WIDGETS(): string { return 'ReusableWcsWidgetsModule'; }
    public static get JWT_TOKEN(): string { return 'JWT_TOKEN'; }
    public static get LOCALE(): string { return 'LOCALE'; }
    public static get ENGLISH(): string { return 'en_US'; }
    public static get SYSTEM_ERROR(): string { return 'Unfortunately, our system is currently experiencing issues. Please try again at a later time. If this problem continues, call 1-866-755-2680 for assistance.'; }
    public static get FIRSTNAME(): string { return 'firstName'; }
    public static get LASTNAME(): string { return 'lastName'; }
    public static get FIRSTLASTNAME(): string { return 'firstLastName'; }
    public static get NOACCESSTOPORTALERROR(): string { return 'NoAccessToPortalError'; }
    public static get SMERRORMSG(): string { return 'sm_error_msg'; }
    public static get SYSTEMERROR(): string { return 'SystemError'; }
    public static get USERID(): string { return 'userId'; }
    public static get ENCRYPTECUSERID(): string { return 'encrypteduserId'; }
    //ER-116520 Checkmarx - UI Issues - May Release
    public static get USERPWD(): string { return 'USERPWD'; }
    public static get ENV_LOCAL(): string { return 'LOCAL'; }
    public static get ENV_PROD(): string { return 'PROD'; }
    public static get HEADER_PERSONA(): string { return 'HEADER_PERSONA'; }
    public static get FOOTER_PERSONA(): string { return 'FOOTER_PERSONA'; }
    public static get MEMBERUSERERROR(): string { return 'memberUserError'; }
    public static get BUSINESSERROR(): string { return 'BUSINESS_ERROR'; }
    public static get SUCCESS(): string { return 'SUCCESS'; }
    public static get FAILURE(): string { return 'FAILURE'; }
    public static get success(): string { return 'success'; }
    public static get failure(): string { return 'failure'; }
    public static get SYS_ERROR(): string { return 'System is currently unavailable. Please try again later.'; }
    public static get ALERT_NEGATIVE(): string { return 'negative'; }
    public static get API_SYSTEM_ERROR(): string { return 'SYSTEM_ERROR'; }
    public static get API_BUSINESS_ERROR(): string { return 'BUSINESS_ERROR'; }
    public static get STATE_CD_CT(): string { return 'connecticut'; }
    public static get STATE_CD_ME(): string { return 'maine'; }
    public static get STATE_CD_NH(): string { return 'newhampshire'; }
    public static get FACETS(): string { return 'FCTCR'; }
    public static get WGS(): string { return 'WGS20'; }
    public static get ISG(): string { return 'STAR'; }
    public static get NASCO(): string { return 'NASCO'; }
    public static get GRS(): string { return 'AGP'; }
    public static get GRS_BRAND(): string { return 'GRSFACETS'; }
    public static get REG_GROUP_NUMBER(): string { return 'REG_GROUP_NUMBER'; }
    public static get REG_GROUP_NAME(): string { return 'REG_GROUP_NAME'; }
    public static get REG_GROUP_OR_CASE(): string { return 'REG_GROUP_OR_CASE'; }
    public static get REG_IS_WGS(): string { return 'REG_IS_WGS'; }
    public static get REG_IS_ISG(): string { return 'REG_IS_ISG'; }
    public static get REG_IS_FACETS(): string { return 'REG_IS_FACETS'; }
    public static get REG_IS_NASCO(): string { return 'REG_IS_NASCO'; }
    public static get REG_IS_GRS(): string { return 'REG_IS_GRS'; }
    public static get REG_BRAND_ID(): string { return 'REG_BRAND_ID'; }
    public static get REG_MBU(): string { return 'REG_MBU'; }
    public static get REG_SRC_SYSTEM(): string { return 'REG_SRC_SYSTEM'; }
    public static get REG_USER_TAX_ID(): string { return 'REG_USER_TAX_ID'; }
    public static get REG_USER_ZIP_CODE(): string { return 'REG_USER_ZIP_CODE'; }
    public static get REG_GRP_EET_IND(): string { return 'REG_GRP_EET_IND'; }
    public static get REG_GROUP_USER_INFO(): string { return 'REG_GROUP_USER_INFO'; }
    public static get REG_CLAIMS_IND(): string { return 'REG_CLAIMS_IND'; }
    public static get REG_USR_INFO(): string { return 'REG_USR_INFO'; }
    public static get REG_USR_ID(): string { return 'REG_USR_ID'; }
    public static get REG_USR_ID_FOR_CHANGE_PASSWORD(): string { return 'REG_USR_ID_FOR_CHANGE_PASSWORD'; }
    public static get REG_USR_ID_FOR_NEW_TPA(): string { return 'REG_USR_ID_FOR_NEW_TPA'; }
    public static get REG_USER_ENTITY_TYPE(): string { return 'REG_USER_ENTITY_TYPE'; }
    public static get GROUP_USAGE_AGREEMENT(): string { return 'GROUP_USAGE_AGREEMENT'; }
    public static get SRC_SYS_ACES(): string { return 'ACES'; }
    public static get SRC_SYS_CS90(): string { return 'CS90'; };
    public static get SRC_SYS_CHIPS(): string { return 'CHIPS'; };
    public static get SELF_REG_GRP_STATUS_ACTIVE(): string { return 'ACTIVE'; }
    public static get SELF_REG_GRP_STATUS_INACTIVE(): string { return 'INACTIVE'; }
    public static get SELF_REG_GRP_STATUS_NOT_AVBL(): string { return 'NOT_AVAILABLE'; }
    public static get SELF_REG_GRP_STATUS_XCHG_GRP(): string { return 'EXCHANGE_GROUP'; }
    public static get SELF_REG_GRP_STATUS_EXISTS_IN_DB(): string { return 'EXISTS_IN_DB'; }
    public static get GRS_TAX_ID_AVAILABLE(): string { return 'GRS_TAX_ID_AVAILABLE'; }

    public static get CASE(): string { return 'Case'; }
    public static get GROUP(): string { return 'Group'; }

    public static get REDIRECT_TO_LOGIN(): string { return 'REDIRECT_TO_LOGIN'; }

    public static get USER_FOUND_IAM(): string { return 'USER_FOUND_IAM'; }
    public static get USER_FOUND_SAME_GROUP(): string { return 'USER_FOUND_SAME_GROUP'; }
    public static get USER_FOUND_DB(): string { return 'USER_FOUND_DB'; }
    public static get USER_NOT_FOUND(): string { return 'USER_NOT_FOUND'; }

    public static get PHONE_NUM(): string { return 'PHONE_NUM'; }
    public static get EMAIL_ADD(): string { return 'EMAIL_ADD'; }
    public static get MASKED_PHONE_NUM(): string { return 'MASKED_PHONE_NUM'; }
    public static get MASKED_EMAIL_ADD(): string { return ' MASKED_EMAIL_ADD'; }
    public static get PROMPT_FOR_DEVICE_UPDATE(): string { return 'PROMPT_FOR_DEVICE_UPDATE'; }

    public static get SELF_REGISTRATION(): string { return 'SELF_REGISTRATION'; }
    public static get USER_AGREEMENT(): string { return 'USER_AGREEMENT'; }
    public static get LOGO_CODE(): string { return 'LOGO_CODE'; };
    public static get GROUP_STATE_CODE(): string { return 'GROUP_STATE_CODE'; }
    public static get HYBRID_IND(): string { return 'HYBRID_IND'; }
    public static get NATIONAL_INDICATOR(): string { return 'NATIONAL_INDICATOR'; }
    public static get SELF_REGISTRATION_RESPONSE(): string { return 'SELF_REGISTRATION_RESPONSE'; }
    public static get APPID(): string { return 'EEA'; }
    public static get MESSAGEID(): string { return '123457'; }
    public static get GROUP_CONTACT_EMAIL(): string { return 'GROUP_CONTACT_EMAIL'; };
    public static get DIVISIONAL_CODE_ARRY(): string { return 'DIVISIONAL_CODE_ARRY'; }

    public static get FIRST_NAME_AGREE(): string { return 'FIRST_NAME_AGREE'; }
    public static get LAST_NAME_AGREE(): string { return 'LAST_NAME_AGREE'; }
    public static get FIRST_LAST_NAME_AGREE(): string { return 'FIRST_LAST_NAME_AGREE'; }
    public static get ISACCEPT_AGREE(): string { return 'ISACCEPT_AGREE'; }

    public static get ISNORMAL_FLOW(): string { return 'ISACCEPT_AGREE'; }
    public static get HASFULLACCESS(): string { return 'HASFULLACCESS'; }

    public static get EASYPAY_EXTERNAL(): string { return 'easypay-external'; }
    public static get EASYPAY_EXTERNAL_PAYMENT_AMOUNT(): string { return 'easypay-external/payment-amount'; }
    public static get EASYPAY_EXTERNAL_PAYMENT_MEHTOD(): string { return 'easypay-external/payment-method'; }
    public static get EASYPAY_EXTERNAL_REVIEW_AUTH(): string { return 'easypay-external/review-auth'; }
    public static get EASYPAY_EXTERNAL_CONFIRMATION(): string { return 'easypay-external/confirmation'; }
    public static get EASYPAY_BRAND_NAME(): string { return 'EASYPAY_BRAND_NAME'; }
    public static get EASYPAY_LOGO_CODE(): string { return 'EASYPAY_LOGO_CODE'; }
    public static get EASYPAY_TAG_LINES(): string { return 'EASYPAY_TAG_LINES'; }
    public static get LOGO_BCBS(): string { return 'BlueCross BlueShield of Georgia'; }
    public static get LOGO_ANTHEM_BCBS(): string { return 'Anthem BlueCross BlueShield'; }
    public static get LOGO_EBCBS(): string { return 'Empire Blue Cross Blue Shield'; }
    public static get LOGO_EBC(): string { return 'Empire Blue Cross'; }
    public static get LOGO_ABS(): string { return 'Anthem BlueCross'; }
    public static get LOGO_UNICARE(): string { return 'Unicare'; }
    public static get BCC(): string { return 'BCC'; }
    public static get EBCBS(): string { return 'EBCBS'; }
    public static get EBC(): string { return 'EBC'; }
    public static get UNICARE(): string { return 'UNICARE'; }
    public static get UNI(): string { return 'UNI'; }
    public static get WLP(): string { return 'WLP'; }
    public static get WLM(): string { return 'WLM'; }
    public static get EMPIREBLUE(): string { return 'EMPIREBLUE'; }
    public static get EMBCBS(): string { return 'EMBCBS'; }
    public static get EMPBC(): string { return 'EMPBC'; }
    public static get BCBSNY(): string { return 'BCBSNY'; }
    public static get BCNY(): string { return 'BCNY'; }
    public static get GRSFACETS(): string { return 'GRSFACETS'; }
    public static get ABC(): string { return 'ABC'; }
    public static get ABCBS(): string { return 'ABCBS'; }
    public static get ABCBSCF(): string { return 'ABCBSCF'; }
    public static get BLUEMA(): string { return 'BLUEMA'; }
    public static get BCBSKC(): string { return 'BCBSKC'; }
    public static get EMPEMB(): string { return 'EMPEMB'; }
    // member registration page
    public static get INVALID_SECURITY_KEY_OR_DOB(): string { return 'InValid_Security_Key_Or_Date_Of_Birth'; }
    public static get enroll(): string { return 'enroll'; }
    public static get decline(): string { return 'decline'; }
    public static get MEMBER_REGISTRATION_DATA(): string { return 'MEMBER_REGISTRATION_DATA'; }
    public static get getStartedPage(): string { return 'getStartedPage'; }
    public static get createAnAccountPage(): string { return 'createAnAccountPage'; }
    public static get securityQuestionsPage(): string { return 'securityQuestionsPage'; }
    public static get completeRegistrationPage(): string { return 'completeRegistrationPage'; }
    public static get thankyouPage(): string { return 'thankyouPage'; }
    public static get waiverOfCoveragePage(): string { return 'waiverOfCoveragePage'; }
    public static get waiverConfirmationPage(): string { return 'waiverConfirmationPage'; }
    public static get option(): string { return 'option'; }
    public static get UserId_already_Exists(): string { return 'UserId_already_Exists'; }
    public static get Security_key_And_Date_Of_Birth_In_DB(): string { return 'Security_key_And_Date_Of_Birth_In_DB'; }
    public static get User_Not_Registered(): string { return 'User_Not_Registered'; }
    public static get PAGE_REDIRECT(): string { return 'pageRedirect'; }
    public static get MEMBER_LOGIN_PAGE(): string { return 'memberLoginPage'; }
    public static get LOGIN_PAGE(): string { return 'loginPage'; }
    public static get WAIVE_COVERAGE_DATA(): string { return 'WAIVE_COVERAGE_DATA'; }
    public static get EMPL_STATUS_WAIVED(): string { return 'WAIVED'; }
    public static get EMPL_STATUS_ENROLLED(): string { return 'ENROLLED'; }
    public static get EMPL_STATUS_REGISTERED(): string { return 'REGISTERED'; }
    public static get ENROLLMENT_DATE_COMPLETED(): string { return 'Enrollment_Date_Completed'; }

    // Broker SSO
    public static get BROKER_REGISTRATION_DATA(): string { return 'BROKER_REGISTRATION_DATA'; }
    public static get brokerLoginPage(): string { return 'brokerLoginPage'; }
    public static get brokerDemographicsPage(): string { return 'brokerDemographicsPage'; }
    public static get brokerUsageAgreementPage(): string { return 'brokerUsageAgreementPage'; }
    public static get brokerMemberAccessPage(): string { return 'brokerMemberAccessPage'; }
    public static get brokerBillDivisionAccessPage(): string { return 'brokerBillDivisionAccessPage'; }
    public static get BROKER_REQUEST_KEY(): string { return 'BROKER_REQUEST_KEY'; }
    public static get BROKER(): string { return 'BROKER'; }
    public static get BROKER_SUCCESS(): string { return 'BROKER_SUCCESS'; }
    public static get on(): string { return 'on'; }
    public static get viewOnly(): string { return 'viewOnly'; }
    public static get STRING_F(): string { return 'F'; }
    public static get STRING_V(): string { return 'V'; }
    public static get STRING_N(): string { return 'N'; }
    public static get DISABLED_STS(): string { return 'D'; }
    public static get noAccess(): string { return 'noAccess'; }
    public static get GROUP_AGREEMENT(): string { return 'GROUP_AGREEMENT'; }
    public static get AGREEMENT_ID(): string { return 'AGREEMENT_ID'; }
    public static get ELECTRONIC_SIGN(): string { return 'ELECTRONIC_SIGN'; }

    public static get PING_DEVICE_ID(): string { return 'PING_DEVICE_ID'; }
    public static get PING_RISK_ID(): string { return 'PING_RISK_ID'; }
    public static get PING_USER_ID(): string { return 'PING_USER_ID'; }
    public static get INVALID_TAX_ZIP_CODE(): string { return 'INVALID_TAX_ZIP_CODE'; }

    public static get ClientCorrelationId(): string {
        return 'ClientCorrelationId';
    }
    public static get MBU(): string {
        return 'mbu';
    }
    public static get BRAND(): string {
        return 'brand';
    }
    public static get BRANDBCC(): string {
      return 'BCC';
    }
    public static get BRANDBCBSGA(): string {
      return 'BCBSGA';
    }
    public static get BRANDBCBSCO(): string {
      return 'BCBSCO';
    }
    public static get BRANDBCBSNV(): string {
      return 'BCBSNV';
    }
    public static get BRANDFACETS(): string {
      return 'FACETS';
    }
    public static get BRANDUNICARE(): string {
      return 'UNICARE';
    }
    public static get BRANDEMPIREBLUE(): string {
      return 'EMPIREBLUE';
    }
    public static get BRANDNORTHEAST(): string {
      return 'NORTHEAST';
    }
    public static get BRANDNATIONAL(): string {
      return 'NATIONAL';
    }
    public static get BRANDVIRGINIA(): string {
      return 'VIRGINIA';
    }
    public static get BRANDCENTRAL(): string {
      return 'CENTRAL';
    }
    public static get SRCSYSTEM(): string {
        return 'srcSystem';
    }
    public static get GROUPNUMBER(): string {
        return 'groupNumber';
    }
    public static get SUBSCRIBERID(): string {
        return 'SUBSCRIBERID';
    }
    public static get GROUP_INFO(): string {
        return 'GROUP_INFO';
    }
    public static get STATE_NH(): string {
        return 'NH';
    }
    public static get FEDERAL_EXCHANGE(): string {
        return 'FE';
    }
    public static get STATE_EXCHANGE(): string {
        return 'SE';
    }
    public static get EVA_CHANNEL(): string {
        return 'Web';
    }
    // added to return ome useName.
    public static get OME_USERID(): string { return 'omeUserId'; }

    public static get RELATED_MEMBERSHIP_GROUP(): string {
        return 'relatedMembershipGroup';
    }
    public static get MOBILE_USER(): string {
        return 'mobileUser';
    }
    public static get REGION(): string {
        return 'region';
    }

    public static get USER_DISABLED_CODE(): string { return '7612'; }

    public static get USER_LOCKED_CODE(): string { return '9834'; }

    public static get USER_BROKER_USER(): string { return '2471'; }

    public static get USER_OTHER_USER(): string { return '8612'; }

    public static get IS_CHANGE_PASSWORD_FLOW(): string { return 'IS_CHANGE_PASSWORD_FLOW'; }

    public static get OTP_VERIFIED(): string { return 'OTP_VERIFIED'; }

    public static get HIDE_SMARTBANNER(): string { return 'HIDE_SMARTBANNER'; }

    public static get ERROR_1001(): string { return 'ERROR_1001'; }
    public static get ERROR_1002(): string { return 'ERROR_1002'; }
    public static get ERROR_1003(): string { return 'ERROR_1003'; }
    public static get ERROR_1004(): string { return 'ERROR_1004'; }
    public static get MFA_MGS_TRYAGAIN(): string { return 'Your code was entered incorrectly. Please double check and try again. After __NUMBER_OF_TRY_LEFT__ more incorrect attempt__MORE_THAN_ONE_TRY__ you\'ll need a new security code. Or, select <b>Didn\'t get a security code</b> now to have a new code sent to you.'; }
    public static get MFA_MGS_LOCKOUT(): string { return 'You\'ve gone over the number of security code attempts allowed. Please select <b>Didn\'t get a security code</b> to have a new code sent to you.'; }
    public static get MFA_MGS_INVALID(): string { return 'Security Code is expired. Please generate a new Security Code.'; }
    public static get MFA_MGS_SIX_DIGIT(): string { return 'Please enter at least 6 numbers.'; }
    public static get MFA_MGS_NUMBER_ONLY(): string { return 'Please enter only numbers.'; }

    public static get CHANGE_PASSWORD_FLOW(): string { return 'change_password'; }
    public static get FORGOT_PASSWORD_FLOW(): string { return 'forgot_password'; }
    public static get LOGIN_FLOW(): string { return 'login'; }
    public static get GRS_LOGOCODE_EMPEMB(): string { return 'EMPEMB'; }
    public static get GRS_LOGOCODE_BLUEMA(): string { return 'BLUEMA'; }
    public static get ERROR(): string { return 'ERROR'; }
    public static get GRP_NAME_NOT_MATCH(): string  { return 'GRP_NAME_NOT_MATCH'; }
    public static get NO_DATA_FOUND (): string  { return 'NO DATA FOUND'; }
    public static get DEACTIVATED (): string  { return 'DEACTIVATED'; }
    public static get INVALID_TAX_ID (): string  { return 'INVALID_TAX_ID'; } 
    public static get INVALID_ZIP_CODE (): string  { return 'INVALID_ZIP_CODE'; } 

    //Login redesign starts
    public static get MFA_DETAILS(): string { return 'MFA_DETAILS'; }
    public static get CHANGE_PASSWORD(): string { return 'CHANGE_PASSWORD' };
    public static get CODE_DEVICE(): string { return 'CodeDevice' };
    //ER-119852 #Enhance Email Address Validation and Centralize Code - Admin
    public static get GET_REGEX(): string { return 'getRegularExpression' };

    // Self Registration
    public static get SPECIFIC_BILLING_ACCOUNTS(): string { return 'SPECIFIC_BILLING_ACCOUNTS'; }
    public static get SPECIFIC_BILLING_DIVISIONS(): string { return 'SPECIFIC_BILLING_DIVISIONS' };
    public static get ALL_BILLING_ACCOUNTS(): string { return 'ALL_BILLING_ACCOUNTS' };
    public static get NO_ACCESS_CLAIM_ERROR(): string { return 'NO_ACCESS_CLAIM_ERROR'; }
    public static get ALL_NO_ACCESS_ERROR(): string { return 'ALL_NO_ACCESS_ERROR'; }
    public static get FULL_ACCESS(): string { return 'FULL_ACCESS'; }
    public static get VIEW_ONLY(): string { return 'VIEW_ONLY'; }
    public static get PROGRESS_STEPS(): string { return 'PROGRESS_STEPS'; }
    public static get SELF_REGISTRATION_REQUEST(): string { return 'SELF_REGISTRATION_REQUEST'; }
    public static get BILLING_ACCOUNT(): string { return 'BillingAccount'; }
    public static get BILLING_DIVISION(): string { return 'BillingDivision'; }
    public static get SPECIFIC(): string { return 'specific'; }
    public static get NAVIGATION(): string {return 'navigation'}
    public static get MULTIPLE_GRP_FOUND(): string {return 'MULTIPLE_GRP_FOUND'}
    public static get SERVICE_EXCEPTION(): string {return 'SERVICE_EXCEPTION'}
    public static get INTERNAL_USER_ERROR(): string {return 'INTERNAL_USER_ERROR'}
    public static get CANCEL(): string {return 'cancel'}
    public static get BROKER_LBL(): string {return 'broker'}
    public static get FULLY_INSURED(): string { return 'Fully Insured' };
    public static get ADMINISTRATIVE_SERVICES_OPERATIONS(): string { return 'Administrative Services Operations' };
    public static get FI(): string { return 'FI' };
    public static get ASO(): string { return 'ASO' };
    public static get PROGRESSBAR_STEPS(): any {
        return {
            groupInfo: { label: 'Group Information', stepName: 'groupInformation' },
            userInfo: { label: 'User Information', stepName: 'userInformation' },
            membershipInfo: { label: 'Membership', stepName: 'membershipInformation' },
            billingInfo: { label: 'User Access', stepName: 'billingInformation' },
            userAgreementInfo: { label: 'Sign & Submit', stepName: 'userAgreement' },
            userSuccessInfo: { label: '', stepName: 'userSuccess' },
        }
    }
    public static get ALL_BILLING_DIVISIONS(): string { return 'ALL_BILLING_DIVISIONS' };
    public static get PENDING_USER_DETAILS(): string { return 'PENDING_USER_DETAILS'; }
    public static get BILLING_FULL_ACCESS(): string { return 'BILLING_FULL_ACCESS'; }
    public static get BILLING_VIEW_ONLY(): string { return 'BILLING_VIEW_ONLY'; }
    public static get FULL_MEMBERSHIP_ACCESS(): string { return 'fullMembershipAccess'}
    public static get BILLING_MEMBERSHIP_ACCESS(): string { return 'Billing' }
    public static get FINISH(): string { return 'FINISH'; }
    public static get DENY(): string { return 'deny'; }
    public static get FULL_MEMBERSHIP(): string { return 'Full Membership'; }
    public static get VIEW_MEMBERSHIP(): string { return 'View Membership'; }
    public static get MEMBER_SUPPORT_VENDOR_LBL(): string {return 'memberSupportVendor'}
    public static get TPA_SITE_ADMIN(): string { return 'TPA_SITE_ADMIN'; }
    public static get IS_TPA_USER(): string { return 'IS_TPA_USER'; }
    public static get NEW_TPA_USER(): string { return 'newTpaUser'; }
    public static get TPA_NAME(): string { return 'TPA_NAME'; }
    public static get TPA_PENDING_USER(): string { return 'TPA_PENDING_USER'; }
    
}
