import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { DynamicLoadModuleUtil } from 'anthem-eea-library-ui';

@Component({
  selector: 'app-secure-privacy-block',
  templateUrl: './secure-privacy-block.component.html',
  styles: []
})
export class SecurePrivacyBlockComponent implements OnInit, AfterContentInit{
  @Input() wcsdata: any;

  public privacyContentWcsData: any;
  public showPrivacy: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  /**
   * Method to call after Initiating the component
   */
  ngAfterContentInit() {
    if (this.wcsdata != null) {
      this.privacyContentWcsData = this.wcsdata;
    }
  }

}

DynamicLoadModuleUtil.registerEntryComponent("SecurePrivacyBlockComponent", SecurePrivacyBlockComponent);
