<fwc-modal [id]="'global-modal'" attr.text-header="{{modalModel?.title ? modalModel.title : ''}}">
  <div slot="body">
    <div *ngIf="!modalModel.custom" [innerHTML]="modalModel?.content"></div>
    <div [hidden]="modalModel.custom" class="modal-component-body modal-custom-body er-flex" slot="body" customModal></div>
  </div>
  <div slot="actions">
    <button *ngIf="modalModel.actions.secondary" class="fwc-btn fwc-btn-secondary" id="btnClose" (click)="cancelBtnClick()" attr.data-analytics="{{modalModel.actions?.secondary?.analytics}}">{{modalModel.actions?.secondary?.txt}}<span class="sr-only">No</span></button>
    <button class="fwc-btn fwc-btn-primary" id="btnApply" (click)="continueBtnClick()" attr.data-analytics="{{modalModel.actions?.primary?.analytics}}">{{modalModel.actions?.primary?.txt}}<span class="sr-only">Yes</span></button>
  </div>
</fwc-modal>
