import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightText'
})
export class HighlightPipe implements PipeTransform {
  transform(items: any[], searchKeyWord: string) {
    if (!items || !searchKeyWord || items.length === 0) {
      return items;
    }
    const regExp = new RegExp(searchKeyWord, 'gi');
    let newItems = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let val = item.label || item;
      let matchStr = val.match(regExp);
      if (item.label) {
        item.label = matchStr && matchStr.length ? val.replace(regExp, matchStr[0].bold()) : val;
      } else {
        item = matchStr && matchStr.length ? val.replace(regExp, matchStr[0].bold()) : val;
      }
      newItems.push(item);
    }
    return newItems;
  }
}
