<div class="row billing manage-bank-accounts">
  <div class="col-sm-10 col-sm-offset-1">
    <div class="privacy-block">
      <h4>
        <strong>{{privacyContentWcsData.blockHeader}}</strong>
      </h4>
      <p>
        {{privacyContentWcsData.lessContent}}
      </p>

      <div [ngClass]="{'hide': !showPrivacy}">

        <h4>
          <strong>{{privacyContentWcsData.subHeader}}</strong>
        </h4>
        <ul>
          <li>{{privacyContentWcsData.moreContent.line1}}</li>
          <li>{{privacyContentWcsData.moreContent.line2}}</li>
          <li>{{privacyContentWcsData.moreContent.line3}}</li>
          <li>{{privacyContentWcsData.moreContent.line4}}</li>
          <li>{{privacyContentWcsData.moreContent.line5}}</li>
          <li>{{privacyContentWcsData.moreContent.line6}}
            <a href="{{privacyContentWcsData.moreContent.url}}">{{privacyContentWcsData.moreContent.urlContent}}</a>
          </li>
        </ul>
      </div>

      <p>
        <a href="javascript:void(0)" (click)="showPrivacy = !showPrivacy">
          <span [ngClass]="{'hide': !showPrivacy}">{{privacyContentWcsData.showLess}}</span>
          <span [ngClass]="{'hide': showPrivacy}">{{privacyContentWcsData.showMore}}</span>
        </a>
      </p>

    </div>
  </div>
</div>
