import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';


@Injectable()
export class SliderService {

  private sliderData: any = null;
  private sliderToggle: boolean = null;
  private doubleToggle: boolean = null;

  private sliderSubject = new BehaviorSubject<any>(this.sliderToggle);
  public toggleInstance = this.sliderSubject.asObservable();

  private doubleSubject = new BehaviorSubject<any>(this.doubleToggle);
  public doubleInstance = this.doubleSubject.asObservable();

  private dataSubject = new BehaviorSubject<any>(this.sliderData);
  public dataInstance = this.dataSubject.asObservable();


  constructor() {}

  toggleSlider(data) {
    this.sliderSubject.next(data);
  }
  toggleDouble(data) {
      this.doubleSubject.next(data);
  }
  sliderCallback(data) {
    this.dataSubject.next(data)
  }
  

}