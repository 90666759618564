import { Component, ComponentRef, ComponentFactory, ComponentFactoryResolver, OnInit, Input, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { HideOverflowDirective } from "./../../../directives/hideOverflow.directive";
import { SlideOutData } from "./../../models/er-slide-out.model";
import { SliderService } from './../../../services/slider-service';
import { Subscription } from 'rxjs';
import { AlertService } from 'anthem-eea-library-ui';

@Component({
  selector: 'app-er-slide-out',
  templateUrl: './er-slide-out.component.html'
})
export class ErSlideOutComponent implements OnInit, OnDestroy {
  @Input() slider: boolean;
  @Input() sliderData: SlideOutData;
  @ViewChild("sliderContent", { read: ViewContainerRef,static:true }) singleContainer;
  @ViewChild("doubleContent", { read: ViewContainerRef,static:true }) doubleContainer;

  public single: ComponentRef<any>;
  public double: ComponentRef<any>;
  //public slider: boolean = false;
  public doubleSlider: boolean = false;
  public singleSubscription: Subscription;
  public doubleSubscription: Subscription;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private sliderService: SliderService, private alertService: AlertService) { }

  ngOnInit() {
    this.singleSubscription = this.sliderService.toggleInstance.subscribe(
      res => {
        if(res) {
          this.initSingleContent(res);
        }else{
          this.slider = false;
          // this.alertService.clearSliderAlertComponents();
        }
      },
      err => {
        console.log("Unable to subscribe to Slider Service");
      }
    );
    this.doubleSubscription = this.sliderService.doubleInstance.subscribe(
      res => {
        if(res) {
          this.initDoubleContent(res);
        }else{
          this.doubleSlider = false;
          // this.alertService.clearSliderAlertComponents();
        }
      },
      err => {
        console.log("Unable to subscribe to Double Slider Service");
      }
    );
  }

  initSingleContent(res) {
    this.singleContainer.clear();
    let factory = this.componentFactoryResolver.resolveComponentFactory(res.components.single);
    this.single = this.singleContainer.createComponent(factory);
    if(res.config.isSlider) {
      this.single.instance.sliderData = res;
    }
    this.slider = true;
  }

  initDoubleContent(res) {
    this.doubleContainer.clear();
    let factory = this.componentFactoryResolver.resolveComponentFactory(res.components.double);
    this.double = this.doubleContainer.createComponent(factory);
    if(res.config.isSlider) {
      this.double.instance.doubleData = res;
    }
    this.doubleSlider = true;

  }

  ngOnDestroy() {
    if (this.single) {
      this.single.destroy();
    }
    if (this.double) {
      this.double.destroy();
    }
    if (this.singleSubscription)
      this.singleSubscription.unsubscribe();
    if (this.doubleSubscription)
      this.doubleSubscription.unsubscribe();
  }

}
