export const ApiNameList = {
	restApi: {
		baseUrl: 'api/',
		baseUrlWcs: '/',
		GENERATE_JWT_TOKEN: 'eea-user/api/secure/v1/user/generateJWTToken',
		MENU_OF_RESOURCES_API_URL: 'eea-user/api/secure/v1/user/landing',
		MANAGE_EMPLOYER_ACCOUNT: 'eea-ogbint/bankaccount/list',
		ADD_NEW_PAYMENT: 'eea-ogbint/bankaccount/add',
		LOGIN_API_URL: 'eea-user/api/public/v1/user/employerLogin',
		CHANGE_PASSWORD_API_URL: 'eea-user/api/public/v1/user/changePassword',
		GET_PROFILE_DETAILS_URL: 'eea-user/api/public/v1/user/getUserProfile',
		GET_PROFILE_DETAILS_DATA_URL: 'eea-user/api/public/v1/user/getUserProfileData',
		SSO_USER_EXIST_API_URL: 'eea-user/api/public/v1/user/ssoUserExist',
		GET_PROFILE_DETAILS_DB_URL: 'eea-user/api/public/v1/user/getUserProfileDetails',
		VALIDATE_UPDATE_SECRET_QUESTION_ANSWER_URL: 'eea-user/api/public/v1/user/validateUpdateSecretQuestionAnswer',
		CHECK_GROUP_FOR_SELF_REGISTRATION: 'eea-user/api/public/v1/admin/checkGroupForSelfRegistration',
		GET_USER_ACCESS_LEVEL_API_URL: 'eea-user/api/public/v1/admin/getUserAccessLevel',
		GET_PHI_FORM_IND: 'eea-user/api/public/v1/admin/getPHIFormInd',
		GET_GROUP_STATUS: 'eea-user/api/public/v1/admin/getGroupStatus',
		GET_DIVISIONAL_CODE: 'eea-user/api/public/v1/admin/getDivisionalCode ',
		EDIT_GROUP_USER: 'eea-user/api/public/v1/admin/editGroupUser',
		CHECK_USER_EXISTS: 'eea-user/api/public/v1/admin/userNameCheck',
		GET_EXISTING_USERS: 'eea-user/api/public/v1/siteadmin/admin/getExistingUsers',
		VALIDATE_REG_CASE_OR_GROUP_INFO: 'eea-user/api/public/v1/admin/validateSelfRegisCaseOrGroupDetails',
		MFA_LOGIN_THREAT: 'node/eea/login/loginThreat',
		MFA_SEND_OTP: 'node/eea/login/sendOTP',
		MFA_VALIDATE_OTP: 'node/eea/login/validateotp',
		MFA_SAVE_FINGER_PRINT: 'node/eea/login/saveFingerPrint',
		APPROVE_OR_DENY_USER_API: 'eea-user/api/public/v1/admin/approveOrDenyPendingUserDetails  ',
		PENDING_SELF_REGISTRATION_API: 'eea-user/api/public/v1/admin/getPendingSelfRegistrationDetails ',
		SUBMIT_SELF_REGISTRATION_API: 'eea-user/api/public/v1/admin/submitSelfRegistration',
		VALIDATE_ADD_USER_INFO_API: 'eea-user/api/public/v1/admin/validateSelfRegisUserInfo',
		GET_AGREEMENT_DATA: 'eea-user/api/public/v1/employer/getAgreementData',
		SAVE_AGREEMENT_DATA: 'eea-user/api/public/v1/employer/saveAgreementData',
		EASY_PAY: 'eea-ogbint/guestpayment/easypaynew',
		BILLING_EASY_PAY_NEW: 'node/eea/billing/getEasyPayBillAccounts',
		BILLING_EASY_PAY: 'node/eea/billing/easyPayGuestUser',
		BILLING_EASY_PAY_SUBMIT: 'node/eea/billing/submitEasyPay',
		ACH_IDS: 'node/eea/billing/getACHCompanyIds',
		BILLING_PAYMENT_AUTHFORM: 'node/eea/billing/downloadAuth',
		BILLING_VALIDATE_ROUTING_NBR: 'node/eea/billing/validateRoutingNumber',
		CHECK_DUPLICATE_PAYMENT: 'node/eea/billing/checkDuplicatePayment',
		BILLING_EASY_PAY_DOWNLOAD_AUTHORIZATION: 'node/eea/billing/easyPayDownloadAuthorization',
		EASY_PAY_DOWNLOAD_AUTHORIZATION: 'eea-ogbint/guestpayment/easypayAuth',
		VALIDATE_MEMBER_REGISTRATION: 'eea-user/api/public/v1/user/validateMemberRegistration',
		GET_SECURITY_QUESTIONS: 'eea-user/api/public/v1/user/getSecurityQuestions',
		GET_AGGREEMENT_DATA: 'eea-user/api/public/v1/employer/getAgreementData',
		MEMBER_REGISTRATION: 'eea-user/api/public/v1/user/memberRegistration',
		GET_USER_PROFILE: 'eea-user/api/public/v1/user/getUserProfile',
		GET_COVERAGE_EMP_DETAILS: 'eea-user/api/public/v1/employer/getDeclineAllCoverageEMPDetails',
		UPDATE_COVERAGE_EMP_DETAILS: 'eea-user/api/public/v1/employer/updateDeclineAllCoverageEMPDetails',
		VALIDATE_OTP_SELF_REGISTRATION: 'eea-user/api/public/v1/admin/validateOTPforSelfRegistration',
		LOGGING_API_URL: 'eea-utility/api/public/v1/utility/log',
		LOG_LEVEL_API_URL: 'eea-utility/api/public/v1/utility/logLevel',
		APPROVE_DENY_BROKER: 'eea-user/api/public/v1/admin/approveOrDenyPendingBroker',
		GRP_ACCOUNTS_SCID: 'eea-login-service/ui/public/v1/rmb/getGrpAcctsAndScids',
		VALIDATE_PASSWORD_EXPIRY_LINK: 'eea-user/api/public/v1/user/validatePasswordExpiryLink',
		EET_CLIENT_INTAKE_REQUEST: 'eea-employer/api/public/v1/eet/consumeClientIntakeRequest',
		VENDOR_COMPANY_NAME_API_CALL: 'node/eea/eet/vendorCompanyNameApiCall',
		VALIDATE_USER_ID: 'eea-user/api/public/v1/user/validateUser',
		SEND_MOBILE_APP_INVITATION: 'node/eea/login/sendMobileAppInvitation',
		SELF_REGISTRATION: {
			VALIDATE_GROUP_INFO_SELF_REGISTRATION: 'node/eea/self-registration/groupInfo/validate',
			DISPLAY_GROUP_INFO_SELF_REGISTRATION: 'node/eea/self-registration/groupInfo/display',
			GET_GROUPSIZE_FROM_ZIPCODE: 'node/eea/self-registration/groupInfo/getGroupSizeFromZipCode',
			GET_USERINFOMRATION_DISPLAY: 'node/eea/self-registration/userInfo/display',
			CHECK_USER_NAME_API: 'node/eea/self-registration/userInfo/checkUserName',
			GET_MEMBER_SHIP_ACCESS_CATEGORY_DETAILS: 'node/eea/self-registration/membership/display',
			GET_SUB_GROUP_DETAILS: 'node/eea/self-registration/groupInfo/getSubGroupDetails',
			VALIDATE_USER_INFO_SELF_REGISTRATION: 'node/eea/self-registration/userInfo/validate',
			GET_USER_AGREEMENT: 'node/eea/self-registration/userAgreement/getAgreementDetails',
			GET_BILLING_ACCOUNT: 'node/eea/self-registration/billing/displayBillingAccountInfo',
			VALIDATE_MEMBERSHIP_INFO_SELF_REGISTRATION: 'node/eea/self-registration/membership/validate',
			VALIDATE_BILLING_INFO_SELF_REGISTRATION: 'node/eea/self-registration/billing/validate',
			SUBMIT_SELF_REGISTRATION: 'node/eea/self-registration/save',
			PENDING_USER_DETAILS: 'node/eea/self-registration/getPendingUserDetails',
			APPROVE_OR_DENY_PENDING_USER: 'node/eea/self-registration/approveOrDenyPendingUser',
			APPROVE_OR_DENY_PENDING_TPA_USER: 'node/eea/self-registration/approveOrDenyPendingTpaUser',
			MODIFY_ACCESS_SELF_REGISTRATION: 'node/eea/self-registration/approveDenyModifyUser',
			CHECK_LAST_PAGE: 'node/eea/self-registration/check-lastpage',
			GET_TPA_GROUP_LIST: 'node/eea/self-registration/tpa/getTPAGroupList',
			VALIDATE_TPA_GROUP_INFO: 'node/eea/self-registration/tpa/validateTPAGroupInformation',
			VALIDATE_TPA_SITEADMIN_ACCESS: 'node/eea/self-registration/tpa/checkSiteAdmin',
			SUBMIT_USER: 'node/eea/self-registration/addEditUserSubmit',
			GET_TPA_REGISTRATION_LINK: 'node/eea/self-registration/tpa/getTPAResgistraionLink',
		},
		//Login redesign starts
		EMPLOYER_LOGIN_NEW: 'node/eea/login/authenticate',
		RECOVER_USER_NAME: 'node/eea/recovercreds/recoverUserName',
		MFA_SEND_OTP_NEW: 'node/eea/mfa/sendotp',
		RECOVER_PASSWORD: 'node/eea/recovercreds/recoverPassword',
		MFA_VALIDATE_OTP_NEW: 'node/eea/mfa/validateotp',
		MFA_SAVE_FINGER_PRINT_NEW: 'node/eea/mfa/save',
		GET_REGEX: 'node/eea/recovercreds/getRegex'
	}
}
