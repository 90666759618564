import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicLoadComponent } from './dynamic-load.component';
import { WcsApiService } from './../common/services/wcs-api.service';
import { CommonAlertModule, CommonAlertComponent } from './../common/components/reusable-page-widgets/common-alert/common-alert.module';

@NgModule({
  imports: [
    CommonModule,
    CommonAlertModule
  ],
  declarations: [
    DynamicLoadComponent
  ],
  exports: [
    DynamicLoadComponent,
    CommonAlertComponent
  ],
  providers: [
    WcsApiService
  ]
})
export class DynamicLoadModule { }
