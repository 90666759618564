import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CaseGroupHeaderComponent} from "./case-group-header/case-group-header.component";
import {ScrollToFirstInvalidDirective} from "../../directives/scroll-to-first-invalid-field";
import { PageHeaderComponent } from './page-header/page-header.component';
import { PhoneNumberPipe } from '../../../common/pipes/phone-number.pipe';
import { HighlightPipe } from '../../../common/pipes/highlightPipe';
import { FooterInfoComponent } from "../footer-info/footer-info.component";
import { MotifPageHeaderComponent } from "../motif-pl/motif-page-header.component";
import { MotifPaginationComponent } from "../motif-pl/motif-pagination/motif-pagination.component";
import { MotifModalComponent } from "../motif-pl/motif-modal.component";
import { MotifModalSlideComponent } from "../motif-pl/motif-modal-slide.component";
import { ErSlideOutComponent } from './er-slide-out/er-slide-out.component';
import { CustomModalDirective } from "../../directives/custom-modal.directive";
import { ModalDynamicComponent } from "../motif-pl/modal-dynamic.component";
import { MockCustomTemplateComponent } from "../motif-pl/mock-custom-template/mock-custom-template.component";


@NgModule({
  imports: [CommonModule ],
  declarations: [
    CaseGroupHeaderComponent,
    ScrollToFirstInvalidDirective,
    PageHeaderComponent,
    PhoneNumberPipe,
    HighlightPipe,
    FooterInfoComponent,
    MotifPageHeaderComponent,
    MotifPaginationComponent,
    MotifModalComponent,
    MotifModalSlideComponent,
    ErSlideOutComponent,
    CustomModalDirective,
    ModalDynamicComponent,
    MockCustomTemplateComponent
  ],
  exports: [
    CaseGroupHeaderComponent,
    ScrollToFirstInvalidDirective,
    PageHeaderComponent,
    PhoneNumberPipe,
    HighlightPipe,
    FooterInfoComponent,
    MotifPageHeaderComponent,
    MotifPaginationComponent,
    MotifModalComponent,
    MotifModalSlideComponent,
    ErSlideOutComponent,
    CustomModalDirective,
    ModalDynamicComponent,
    MockCustomTemplateComponent
  ],
  bootstrap: [ErSlideOutComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [PhoneNumberPipe, HighlightPipe]
})
export class ReusablePageWidgetsModule { }
