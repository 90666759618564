import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAlertComponent } from './common-alert.component';

export * from './common-alert.component';

@NgModule({
  imports: [
    CommonModule
  ],
  bootstrap: [CommonAlertComponent],
  declarations: [CommonAlertComponent],
  exports: [CommonAlertComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonAlertModule { }
