import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  /*
  This method will mask a phone number into the format xxx-xxx-xxxx
   */
  transform(value: string, args?: any): any {

    if (value != null) {
      let phoneNumberFormat = value.replace(/\D+/g, "").replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "$1-$2-$3");
      return phoneNumberFormat;
    }

  }

}
