import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ComponentFactoryResolver,
  ComponentRef
} from '@angular/core';
import { FwcModal } from '../../../../fwc-lib.js';
import { Router } from "@angular/router";
import { AlertService } from "anthem-eea-library-ui";
import { CustomModalDirective } from "../../directives/custom-modal.directive";
import { ModalDynamicComponent } from "./modal-dynamic.component";

@Component({
  selector: 'mt-modal',
  templateUrl: './motif-modal.component.html'
})
export class MotifModalComponent implements OnInit, OnChanges {
  @Input() globalModal: any;
  @Output() cancelClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() continueClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(CustomModalDirective, { static: true }) customModal: CustomModalDirective;
  public componentRef: ComponentRef<any>;
  modalModel: any;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, public router: Router, public alertService: AlertService){
    this.alertService.instance.subscribe((res) => {
      this.modalModel = res;
    });
  }
  ngOnInit() {
    this.modalModel = this.globalModal;
    const fwcModal = new FwcModal();
    fwcModal.build();
    this.buildModal();
  }
  buildModal() {
    if (this.modalModel.open === true) {
      if (this.modalModel.custom) {
        const viewContainerRef = this.customModal.viewContainerRef;
        viewContainerRef?.clear();

        let customTemplate = new ModalDynamicComponent(this.modalModel.custom.template, {});
        let factory = this.componentFactoryResolver.resolveComponentFactory(customTemplate.component);
        this.componentRef = viewContainerRef?.createComponent(factory);
        if(<ModalDynamicComponent>this.componentRef?.instance){
          (<ModalDynamicComponent>this.componentRef.instance).data = this.modalModel.custom.data;
        }
        
      }
      this.openModal();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.buildModal();
  }

  closeModal() {
    const modal = document.getElementById('global-modal');
    modal.removeAttribute('open');
  }

  cancelBtnClick() {
    this.cancelClick.emit(true);
    this.closeModal();
    if (this.modalModel.actions.secondary.cb) {
      this.modalModel.actions.secondary.cb();
    }
  }

  continueBtnClick() {
    this.continueClick.emit(true);
    this.closeModal();
    if (this.modalModel.actions.primary.cb) {
      this.modalModel.actions.primary.cb();
    }
  }

  openModal() {
    const modal = document.getElementById('global-modal');
    modal.setAttribute('open', '');
  }
}
