import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { FwcAlert } from '../../../../../fwc-lib.js';

// Replaces Information, Negative and Positive Alert Components.

@Component({
  selector: 'app-common-alert',
  templateUrl: './common-alert.component.html',
  styles: []
})
export class CommonAlertComponent implements AfterContentInit {

  @Input() wcsdata: any;
  @Input() type: string;
  @Output() closeClick: EventEmitter<any>;

  public alertData: any;
  public commonAlert: any;


  constructor() {
    const fwcAlert = new FwcAlert();
    fwcAlert.build();
    this.closeClick = new EventEmitter();
  }

  ngAfterContentInit() {
    if (this.wcsdata != null) {
      this.alertData = this.wcsdata;
    }
    this.commonAlert = {
      alertType: this.type
    };
  }

  hideAlert(value) {
    this.closeClick.emit(value);
  }

}

