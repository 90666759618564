<div class="paper-slider-container" [ngClass]="{'open' : slider}" er-hide-overflow>
  <div class="paper-slider single-slider" [ngClass]="{'lock' : doubleSlider}">
        <app-alert-dismissible-container-slider *ngIf="!doubleSlider" class="er-slider-revert-col-max"></app-alert-dismissible-container-slider>
        <ng-template #sliderContent></ng-template>
  </div>
  <div class="paper-slider double-slider" [ngClass]="{'double' : doubleSlider}">
      <app-alert-dismissible-container-slider *ngIf="doubleSlider" class="er-slider-revert-col-max"></app-alert-dismissible-container-slider>
      <ng-template #doubleContent></ng-template>
    </div>
</div>
