import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { ApiNameList } from '../../../environments/api-name-list';
import { UserCacheService } from './user-cache.service';
import { AppConstants } from '../constants/app-constants';
import { environment } from '../../../environments/environment';


@Injectable()
export class HttpResponseTimeLoggerService implements HttpInterceptor {
    private apiToIntercept = ["employerLogin", "generateJWTToken", "landing", "searchUser", "homeopt", "getWIPRecords", "getGroupDetails", "searchGroup", "searchSubscriberById"];
    private _infoLogs = [];
    constructor(
        private logger: NGXLogger, private userCacheService: UserCacheService,
    ) {
        this.logger.updateConfig({ serverLoggingUrl: ApiNameList.restApi.baseUrl + ApiNameList.restApi.LOGGING_API_URL, level: NgxLoggerLevel.OFF, serverLogLevel: NgxLoggerLevel.INFO });
        this.logOnTimeInterval();
    }

    /**
     * this method is used to call the writeLog method on every time interval ex-10 secs
     */
    private logOnTimeInterval() {
        setTimeout(() => {
            this.writeLogs();
        }, environment.logDetails.timeInterval);
    }

    /**
     * This method is used to write logs
     */
    private writeLogs() {
        if (this._infoLogs && this._infoLogs.length > 0) {
            console.log(this._infoLogs);
            this.logger.info("Info logs", this.addAdditionalInputs(this._infoLogs));
            this._infoLogs = [];
        }
        this.logOnTimeInterval();
    }

    /**
     * This method is used to add additional inputs like userId and security token
     * @param  {any} entries - the message content to be logged
     */
    private addAdditionalInputs(entries: any) {
        return {
            userId: this.userCacheService.getUserData(AppConstants.USERID),
            securityToken: this.userCacheService.getUserData(AppConstants.JWT_TOKEN),
            clientCorrelationId: this.userCacheService.getUserData(AppConstants.ClientCorrelationId),
            brandId: this.userCacheService.getUserData(AppConstants.BRAND) ? this.userCacheService.getUserData(AppConstants.BRAND) : '',
            mbu: this.userCacheService.getUserData(AppConstants.MBU) ? this.userCacheService.getUserData(AppConstants.MBU) : '',
            sourceSystem: this.userCacheService.getUserData(AppConstants.SRCSYSTEM) ? this.userCacheService.getUserData(AppConstants.SRCSYSTEM) : '',
            groupNumber: this.userCacheService.getUserData(AppConstants.GROUPNUMBER) ? this.userCacheService.getUserData(AppConstants.GROUPNUMBER) : '',
            entries: entries
        };
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const startTime = Date.now();
        let status: string;
        let apiToLog = false;
        this.apiToIntercept.forEach(api => {
            if (req.url.indexOf(api) > 0) {
                apiToLog = true;
            }
        });
        if (!apiToLog) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            tap(
                event => {
                    status = '';
                    if (event instanceof HttpResponse) {
                        status = 'succeeded';
                        let sizeof = require('object-sizeof');
                        const requestPayloadSizeMsg = "Response Payload Size of " + event.url + " is " + sizeof(event.body) + " bytes";
                        this.logDetails(requestPayloadSizeMsg);
                    }
                },
                error => status = 'failed'
            ),
            finalize(() => {
                const elapsedTime = Date.now() - startTime;
                const message = req.method + " " + req.urlWithParams + " " + status
                    + " in " + elapsedTime + " ms";

                this.logDetails(message);
                if (req.method.toUpperCase() === "POST" || req.method.toUpperCase() === "PUT") {
                    let sizeof = require('object-sizeof');
                    const requestPayloadSizeMsg = "Request Payload Size of " + req.urlWithParams + " is " + sizeof(req.body) + " bytes";
                    this.logDetails(requestPayloadSizeMsg);
                }
            })
        );
    }
    private logDetails(msg: string) {
        this.info(msg, "");
    }

    /**
   * this method is used to write info level message
   * @param  {string} message - the message content to be logged
   * @param  {string} transactionType - the class name where the log is written
   * @param  {string} className - the class name where the log is written
   * @param  {string} methodName - the method name where the log is written
   */
    public info(message: string, transactionType: string, className?: string, methodName?: string) {
        this.appendMessage(message, transactionType, NgxLoggerLevel.INFO, className, methodName);
    }

    /**
     * this method is used to append the log entry to the corresponding array variables
     * @param  {string} message
     * @param  {string} transactionType
     * @param  {NgxLoggerLevel} logLevel
     * @param  {string} className - the class name where the log is written
     * @param  {string} methodName - the method name where the log is written
     */
    private appendMessage(message: string, transactionType: string, logLevel: NgxLoggerLevel, className?: string, methodName?: string) {

        this._infoLogs.push(this.formatMessage(message, transactionType, logLevel, className, methodName));
    }

    /**
     * This method is used to format the log message into specific format
     * @param  {string} message
     * @param  {string} transactionType
     * @param  {NgxLoggerLevel} logLevel
     * @param  {string} className - the class name where the log is written
     * @param  {string} methodName - the method name where the log is written
     */
    private formatMessage(message: string, transactionType: string, logLevel: NgxLoggerLevel, className?: string, methodName?: string) {
        return {
            timestamp: new Date(),
            message: message,
            transactionType: transactionType ? transactionType : '',
            className: className ? className : '',
            methodName: methodName ? methodName : '',
            level: logLevel,
            userAgent: '',
            subscriberId: this.userCacheService.getUserData(AppConstants.SUBSCRIBERID) ? this.userCacheService.getUserData(AppConstants.SUBSCRIBERID) : ''
        };
    }
} 