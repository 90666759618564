<ng-container *ngIf="true">
  <div class="new-chat-container" [ngClass]="{'maximized' : chatMaximized, 'minimized' : !chatMaximized}">
    <div *ngIf="!chatMaximized && unreadMessages > 0" class="chat-unread-messages">{{unreadMessages}}</div>
    <!-- <div class="chat-titlebar er-flex" (click)="toggleChat()">
      <div class="er-flex">
        <div class="fa-chat-outline"></div>
        <div *ngIf="chatMaximized" class="chat-title">Employer Assistant</div>
      </div>
      <div *ngIf="chatMaximized" class="chat-toggle txt-btn open er-expand-plus"></div>

    </div> -->
    <!-- New Icon Added -->
    <div class="chat-titlebar er-flex" [style.background]="chatMaximized ? '#37475a': ''" (click)="toggleChat()">
      <div class="er-flex">
        <div *ngIf="!chatMaximized" ><img src="assets/images/sydney-collapsed.png" alt="chat-minimized"></div>
        <div *ngIf="chatMaximized" ><img src="assets/images/sydney-light.png" alt="chat-maximized"></div>
        <div *ngIf="chatMaximized" class="chat-title">Employer Assistant</div>
      </div>
      <div *ngIf="chatMaximized" class="chat-toggle txt-btn open er-expand-plus"></div>
    </div>

    <div *ngIf="chatMaximized" class="chat-convo-container" id="main">
      <div class="agent-message timestamp">{{dateTime | date:'mediumDate'}}, {{dateTime | date:'shortTime'}}</div>
         <!--Display agent messages If ACA2.0 Enabled-->
        <ng-container *ngFor="let chatMessage of chatMessages">
          <div class="displayMsg">
            <div class="iconDisplay" *ngIf="chatMessage.sender !== 'user'"><img src="assets/images/sydney-light.png"></div>
          <div class="agent-message" [ngClass]="{'action-selection' : chatMessage.sender === 'user'}"
            *ngIf="chatMessage.message.text !=='' && !chatMessage.message.attachment" [innerHtml]="chatMessage.message.text">

          </div>
          <div class="agent-message" [ngClass]="{'action-selection' : chatMessage.sender === 'user'}"
            *ngIf="chatMessage?.message.attachment && chatMessage.message.attachment.attributes"
            [innerHtml]="chatMessage.message.attachment.attributes[0].value"></div>
          </div>
        </ng-container>

      <div class="er-flex-column">
        <!--Display action buttons If ACA2.0 Enabled-->
          <ng-container  *ngFor="let chatMessage of chatMessages">
            <span *ngIf="chatMessage?.message.attachment &&  chatMessage.message.attachment.attachments?.length > 0"
              class="chat-actions">
              <div class="er-flex" *ngFor="let action of chatMessage.message.attachment.attachments">
                <button class="action-button" [innerHtml]="action.title"
                  (click)="selectAction(action.title, action.payload)"></button>
              </div>
            </span>
          </ng-container>

          <!--display this container when bot is typing-->
          <ng-container *ngIf="botIsTyping">
            <span class="chat-agent-typing">
              <img src="./assets/images/loading_text.gif" alt="Anthem Inc." class="typing-icon">
            </span>
          </ng-container>

        <!--display this container after last bot message to poll user-->
        <ng-container *ngFor="let chatMessage of chatMessages">
          <div class="er-flex" *ngIf="chatMessage.message.text=='How was your experience with me so far?'">
            <div><button class="eva-bot-options"  *ngFor="let action of chatMessage.message.quick_replies"  (click)="selectAction(action.title, action.payload)">
                <div *ngIf="action.payload=='thumbs_up'">?</div><div *ngIf="action.payload!='thumbs_up'">?</div>
              </button></div></div>
</ng-container>
      </div>
    </div>
    <div *ngIf="chatMaximized" class="chat-input-container er-flex">
      <input class="chat-box-input-textarea"
                id="chat-box-input-textarea"
                rows="1"
                [(ngModel)]="userResponse"
                #textInputArea="ngModel"ng-trim="true"
                [attr.placeholder]="'Type your reply here'"
                (keydown.enter)="sendUserResponse(userResponse)" [maxlength]="1000">
      <div id="chat-send-btn" class="chat-btn-send" (click)="sendUserResponse(userResponse)" data-analytics="sendChatMessage">
      </div>
    </div>
  </div>
</ng-container>
