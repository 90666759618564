<div class="main-header" *ngIf="!showEamobileTemplate && !easypay">
    <shared-app-logos [logo]="logo"></shared-app-logos>
</div>
<div class="main-header-easypay" *ngIf="!showEamobileTemplate && easypay">
    <shared-app-logos [logo]="logo"></shared-app-logos>
</div>
<div class="main-wrap">
    <router-outlet></router-outlet>
</div>
<app-chat *ngIf="!mobileUser" (miniMizeChatBot)="miniMizeChatBot($event)"></app-chat>
<mt-modal [globalModal]="globalModal"></mt-modal>
<app-alert-dismissible-container></app-alert-dismissible-container>
